import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import * as fromSeriousSystem from '@serious-system';

@Component({
  selector: 'squadbox-not-found',
  imports: [RouterModule, fromSeriousSystem.ButtonDirective],
  template: `
    <div class="flex items-center justify-center h-screen">
      <div class="text-center">
        <h1 class="typo-display1 text-neutral-300">404</h1>
        <p class="mb-1">
          Sorry, the page you are looking for could not be found.
        </p>
        <p class="mb-4">
          But don't worry, you can find plenty of other things on our
          playground.
        </p>
        <a sdButton variant="tonal" color="neutral" routerLink="/playground">
          Go to Playground
        </a>
      </div>
    </div>
  `,
  styles: [``],
})
export class NotFoundPage {}
